<div style="margin: 10px;">
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>&Uacute;ltima actualizaci&oacute;n: Marzo 22, 2023</p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Por favor lea nuestra pol&iacute;tica de privacidad antes de usar nuestros servicios.</p>
    <div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Pol&iacute;ticas de privacidad</p>
    </div>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Esta Pol&iacute;tica de privacidad describe c&oacute;mo Pr&oacute;xima Financial Technology recopila, utiliza y comparte informaci&oacute;n personal de los usuarios de nuestra aplicaci&oacute;n m&oacute;vil (&quot;Pr&oacute;xima App&quot;).</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Recopilaci&oacute;n de informaci&oacute;n</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Podemos recopilar informaci&oacute;n personal que usted nos proporciona, como su nombre, direcci&oacute;n de correo electr&oacute;nico y otra informaci&oacute;n que pueda identificarlo. Tambi&eacute;n podemos recopilar informaci&oacute;n de uso de la aplicaci&oacute;n, como su ubicaci&oacute;n, el dispositivo que est&aacute; utilizando y la forma en que utiliza la aplicaci&oacute;n.</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Uso de la informaci&oacute;n</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Utilizamos la informaci&oacute;n personal que recopilamos para proporcionarle una mejor experiencia de usuario en la aplicaci&oacute;n. Tambi&eacute;n podemos utilizar su informaci&oacute;n personal para fines de marketing y publicidad, siempre y cuando usted nos haya dado su consentimiento para hacerlo.</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Compartir informaci&oacute;n</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Podemos compartir su informaci&oacute;n personal con terceros que trabajan en nuestro nombre para proporcionarle la aplicaci&oacute;n, como nuestros proveedores de servicios. Tambi&eacute;n podemos compartir su informaci&oacute;n personal si estamos obligados a hacerlo por ley o para proteger nuestros derechos legales.</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Seguridad de la informaci&oacute;n</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Tomamos medidas razonables para proteger su informaci&oacute;n personal de la p&eacute;rdida, el uso indebido y el acceso no autorizado. Sin embargo, no podemos garantizar la seguridad de su informaci&oacute;n personal.</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Cambios en esta pol&iacute;tica</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Podemos cambiar esta Pol&iacute;tica de privacidad en cualquier momento. Si hacemos cambios importantes en la forma en que tratamos su informaci&oacute;n personal, le notificaremos mediante un aviso en la aplicaci&oacute;n o por correo electr&oacute;nico.</p>
    <h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Contacto</h2>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Si tiene alguna pregunta sobre esta Pol&iacute;tica de privacidad, puede ponerse en contacto con nosotros a trav&eacute;s de nuestra p&aacute;gina web&nbsp;<a href="http://www.proxima.com.do">www.proxima.com.do</a>. Agradecemos que haya elegido utilizar nuestra aplicaci&oacute;n y estamos comprometidos a proteger su privacidad.</p>
</div>