<div style="margin: 10px;">
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>T&eacute;rminos y Condiciones</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>&Uacute;ltima actualizaci&oacute;n: Marzo 22, 2023</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Por favor lea estos t&eacute;rminos y condiciones cuidadosamente antes de utilizer nuestros servicios.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Interpretaci&oacute;n y Definiciones</p>
</div>
<h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Interpretaci&oacute;n</h2>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Las palabras cuya letra inicial est&aacute; en may&uacute;scula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendr&aacute;n el mismo significado independientemente de que aparezcan en singular o en plural.</p>
<h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Definiciones</h2>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>A los efectos de estos T&eacute;rminos y Condiciones:</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Aplicaci&oacute;n</strong> significa el programa de software proporcionado por la Compa&ntilde;&iacute;a descargado por Usted en cualquier dispositivo electr&oacute;nico, denominado Pr&oacute;xima App</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Tienda de aplicaciones</strong> significa el servicio de distribuci&oacute;n digital operado y desarrollado por Apple Inc. (Apple App Store) o Google Inc. (Google Play Store) en el que se ha descargado la Aplicaci&oacute;n.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Afiliado&nbsp;</strong>significa una entidad que controla, es controlada o est&aacute; bajo control com&uacute;n con una parte, donde &quot;control&quot; significa la propiedad del 50% o m&aacute;s de las acciones, participaci&oacute;n accionaria u otros valores con derecho a voto para la elecci&oacute;n de directores u otra autoridad administrativa.&nbsp;</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Pa&iacute;s</strong> se refiere a: Rep&uacute;blica Dominicana.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Compa&ntilde;&iacute;a</strong> (referida como &quot;la Compa&ntilde;&iacute;a&quot;, &quot;Nosotros&quot;, &quot;Nos&quot; o &quot;Nuestro&quot; en este Acuerdo) se refiere a <strong>Pr&oacute;xima Financial Technology.</strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un tel&eacute;fono celular o una tableta digital.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Servicio</strong> se refiere a la Aplicaci&oacute;n.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Los T&eacute;rminos y condiciones</strong> (tambi&eacute;n denominados &quot;T&eacute;rminos&quot;) significan estos T&eacute;rminos y condiciones que forman el acuerdo completo entre Usted y la Compa&ntilde;&iacute;a con respecto al uso del Servicio. Este acuerdo de T&eacute;rminos y Condiciones ha sido creado con la ayuda del Generador de T&eacute;rminos y Condiciones.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Servicio de redes sociales de terceros</strong> significa cualquier servicio o contenido (incluidos datos, informaci&oacute;n, productos o servicios) proporcionado por un tercero que puede mostrarse, incluirse o ponerse a disposici&oacute;n mediante el Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'><strong>Usted</strong> se refiere a la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, seg&uacute;n corresponda.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Reconocimiento</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Estos son los T&eacute;rminos y Condiciones que rigen el uso de este Servicio y el acuerdo que opera entre Usted y la Compa&ntilde;&iacute;a. Estos T&eacute;rminos y Condiciones establecen los derechos y obligaciones de todos los usuarios con respecto al uso del Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Su acceso y uso del Servicio est&aacute; condicionado a Su aceptaci&oacute;n y cumplimiento de estos T&eacute;rminos y condiciones. Estos T&eacute;rminos y condiciones se aplican a todos los visitantes, usuarios y otras personas que acceden o utilizan el Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos T&eacute;rminos y condiciones. Si no est&aacute; de acuerdo con alguna parte de estos T&eacute;rminos y condiciones, no podr&aacute; acceder al Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Usted declara que es mayor de 18 a&ntilde;os. La Compa&ntilde;&iacute;a no permite que los menores de 18 a&ntilde;os utilicen el Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Su acceso y uso del Servicio tambi&eacute;n est&aacute; condicionado a Su aceptaci&oacute;n y cumplimiento de la Pol&iacute;tica de Privacidad de la Compa&ntilde;&iacute;a. Nuestra Pol&iacute;tica de privacidad describe Nuestras pol&iacute;ticas y procedimientos sobre la recopilaci&oacute;n, el uso y la divulgaci&oacute;n de Su informaci&oacute;n personal cuando utiliza la Aplicaci&oacute;n o el Sitio web y le informa sobre Sus derechos de privacidad y c&oacute;mo la ley lo protege. Lea atentamente Nuestra Pol&iacute;tica de Privacidad antes de utilizar Nuestro Servicio.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Links a otras p&aacute;ginas Web</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni est&aacute;n controlados por la Compa&ntilde;&iacute;a.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>La Compa&ntilde;&iacute;a no tiene control ni asume ninguna responsabilidad por el contenido, las pol&iacute;ticas de privacidad o las pr&aacute;cticas de los sitios web o servicios de terceros. Adem&aacute;s, reconoce y acepta que la Compa&ntilde;&iacute;a no ser&aacute; responsable, directa o indirectamente, de ning&uacute;n da&ntilde;o o p&eacute;rdida causados o presuntamente causados por o en relaci&oacute;n con el uso o la confianza en dicho contenido, bienes o servicios disponibles en o a trav&eacute;s de dichos sitios web o servicios.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Le recomendamos encarecidamente que lea los t&eacute;rminos y condiciones y las pol&iacute;ticas de privacidad de cualquier sitio web o servicio de terceros que visite.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Terminaci&oacute;n</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Podemos rescindir o suspender Su acceso de inmediato, sin previo aviso ni responsabilidad, por cualquier motivo, incluido, entre otros, si incumple estos T&eacute;rminos y condiciones.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Tras la rescisi&oacute;n, su derecho a utilizar el Servicio cesar&aacute; inmediatamente.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Limitaci&oacute;n de Responsabilidad</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Sin perjuicio de los da&ntilde;os en los que pueda incurrir, la responsabilidad total de la Compa&ntilde;&iacute;a y cualquiera de sus proveedores en virtud de cualquier disposici&oacute;n de estos T&eacute;rminos y Su recurso exclusivo para todo lo anterior se limitar&aacute; al monto que realmente pag&oacute; a trav&eacute;s del Servicio o 100 USD. si no ha comprado nada a trav&eacute;s del Servicio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>En la medida m&aacute;xima permitida por la ley aplicable, en ning&uacute;n caso la Compa&ntilde;&iacute;a o sus proveedores ser&aacute;n responsables de da&ntilde;os especiales, incidentales, indirectos o consecuentes de ning&uacute;n tipo (incluidos, entre otros, da&ntilde;os por lucro cesante, p&eacute;rdida de datos o otra informaci&oacute;n, por interrupci&oacute;n del negocio, por lesiones personales, p&eacute;rdida de privacidad que surja o est&eacute; relacionada de alguna manera con el uso o la incapacidad de usar el Servicio, software de terceros y/o hardware de terceros utilizado con el Servicio, o de lo contrario en relaci&oacute;n con cualquier disposici&oacute;n de estos T&eacute;rminos), incluso si la Compa&ntilde;&iacute;a o cualquier proveedor ha sido advertido de la posibilidad de tales da&ntilde;os e incluso si el remedio no cumple con su prop&oacute;sito esencial.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Algunos estados no permiten la exclusi&oacute;n de garant&iacute;as impl&iacute;citas o la limitaci&oacute;n de responsabilidad por da&ntilde;os incidentales o consecuentes, lo que significa que algunas de las limitaciones anteriores pueden no aplicarse. En estos estados, la responsabilidad de cada parte se limitar&aacute; en la mayor medida permitida por la ley.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Descargo de Responsabilidad</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>El Servicio se le proporciona &quot;TAL CUAL&quot; y &quot;SEG&Uacute;N DISPONIBILIDAD&quot; y con todas las fallas y defectos sin garant&iacute;a de ning&uacute;n tipo. En la medida m&aacute;xima permitida por la ley aplicable, la Compa&ntilde;&iacute;a, en su propio nombre y en nombre de sus Afiliados y sus respectivos otorgantes de licencias y proveedores de servicios, renuncia expresamente a todas las garant&iacute;as, ya sean expresas, impl&iacute;citas, estatutarias o de otro tipo, con respecto a la Servicio, incluidas todas las garant&iacute;as impl&iacute;citas de comerciabilidad, idoneidad para un prop&oacute;sito particular, t&iacute;tulo y no infracci&oacute;n, y garant&iacute;as que puedan surgir del curso de la negociaci&oacute;n, el curso del desempe&ntilde;o, el uso o la pr&aacute;ctica comercial. Sin limitaci&oacute;n a lo anterior, la Compa&ntilde;&iacute;a no ofrece ninguna garant&iacute;a o compromiso, y no hace ninguna representaci&oacute;n de que el Servicio cumplir&aacute; con Sus requisitos, lograr&aacute; los resultados previstos, ser&aacute; compatible o funcionar&aacute; con cualquier otro software, aplicaci&oacute;n, sistema o servicio, operar&aacute; sin interrupci&oacute;n, cumplir con los est&aacute;ndares de rendimiento o confiabilidad o estar libre de errores o que cualquier error o defecto puede o ser&aacute; corregido.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Sin limitar lo anterior, ni la Compa&ntilde;&iacute;a ni ninguno de los proveedores de la compa&ntilde;&iacute;a hace ninguna representaci&oacute;n o garant&iacute;a de ning&uacute;n tipo, expresa o impl&iacute;cita: (i) en cuanto a la operaci&oacute;n o disponibilidad del Servicio, o la informaci&oacute;n, contenido y materiales o productos incluido en el mismo; (ii) que el Servicio ser&aacute; ininterrumpido o libre de errores; (iii) en cuanto a la precisi&oacute;n, confiabilidad o actualidad de cualquier informaci&oacute;n o contenido proporcionado a trav&eacute;s del Servicio; o (iv) que el Servicio, sus servidores, el contenido o los correos electr&oacute;nicos enviados desde o en nombre de la Compa&ntilde;&iacute;a est&aacute;n libres de virus, secuencias de comandos, troyanos, gusanos, malware, bombas de tiempo u otros componentes da&ntilde;inos.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Algunas jurisdicciones no permiten la exclusi&oacute;n de ciertos tipos de garant&iacute;as o limitaciones de los derechos legales aplicables de un consumidor, por lo que es posible que algunas o todas las exclusiones y limitaciones anteriores no se apliquen a usted. Pero en tal caso, las exclusiones y limitaciones establecidas en esta secci&oacute;n se aplicar&aacute;n en la mayor medida exigible seg&uacute;n la ley aplicable.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Ley Rectora</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Las leyes del Pa&iacute;s, excluyendo sus conflictos de leyes, regir&aacute;n estos T&eacute;rminos y Su uso del Servicio. Su uso de la Aplicaci&oacute;n tambi&eacute;n puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Resoluci&oacute;n de Disputas</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Si tiene alguna inquietud o disputa sobre el Servicio, acepta intentar primero resolver la disputa de manera informal comunic&aacute;ndose con la Compa&ntilde;&iacute;a.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Cumplimiento legal de los Estados Unidos</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Usted declara y garantiza que (i) no se encuentra en un pa&iacute;s sujeto al embargo del gobierno de los Estados Unidos, o que ha sido designado por el gobierno de los Estados Unidos como un pa&iacute;s de &quot;apoyo al terrorismo&quot;, y (ii) no est&aacute; incluido en cualquier lista del gobierno de los Estados Unidos de partes prohibidas o restringidas.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Divisibilidad y Renuncia</p>
</div>
<h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Divisibilidad</h2>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Si alguna disposici&oacute;n de estos T&eacute;rminos se considera inaplicable o inv&aacute;lida, dicha disposici&oacute;n se cambiar&aacute; e interpretar&aacute; para lograr los objetivos de dicha disposici&oacute;n en la mayor medida posible seg&uacute;n la ley aplicable y las disposiciones restantes continuar&aacute;n en pleno vigor y efecto.</p>
<h2 style='margin-top:10.0pt;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:17px;font-family:"Calibri",sans-serif;color:#4F81BD;text-align:justify;'>Renuncia</h2>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Salvo lo dispuesto en el presente, la falta de ejercicio de un derecho o de exigir el cumplimiento de una obligaci&oacute;n en virtud de estos T&eacute;rminos no afectar&aacute; la capacidad de una parte para ejercer dicho derecho o exigir dicho cumplimiento en cualquier momento posterior ni la renuncia a un incumplimiento constituir&aacute; una renuncia a cualquier incumplimiento posterior.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;text-align:justify;'>Cambios a estos T&eacute;rminos y Condiciones</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Nos reservamos el derecho, a Nuestro exclusivo criterio, de modificar o reemplazar estos T&eacute;rminos en cualquier momento. Si una revisi&oacute;n es importante, haremos todos los esfuerzos razonables para proporcionar un aviso de al menos 30 d&iacute;as antes de que entren en vigor los nuevos t&eacute;rminos. Lo que constituye un cambio material se determinar&aacute; a Nuestro exclusivo criterio.</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;text-align:justify;'>Al continuar accediendo o utilizando Nuestro Servicio despu&eacute;s de que esas revisiones entren en vigor, Usted acepta estar sujeto a los t&eacute;rminos revisados. Si no est&aacute; de acuerdo con los nuevos t&eacute;rminos, en su totalidad o en parte, deje de usar el sitio web y el Servicio.</p>
<div style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;border:none;border-bottom:solid #4F81BD 1.0pt;padding:0cm 0cm 4.0pt 0cm;'>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:15.0pt;margin-left:0cm;border:none;padding:0cm;font-size:35px;font-family:"Calibri",sans-serif;color:#17365D;'>Contacto</p>
</div>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;'>Si tiene alguna pregunta sobre estos T&eacute;rminos y Condiciones, puede contactarnos:</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:18.0pt;text-indent:-18.0pt;line-height:115%;font-size:15px;font-family:"Cambria",serif!important;'>Por nuestra p&aacute;gina web: www.proxima.com.do</p>
</div>