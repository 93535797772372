import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Nav} from "../services/nav.service";
import {RequestService} from "../services/request.service";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PrincipalMenuComponent } from './components/principal-menu/principal-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {DataTablesModule} from "angular-datatables";
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { UsuariosAddOrEditComponent } from './pages/usuarios-add-or-edit/usuarios-add-or-edit.component';
import { SearchModuleFormComponent } from './components/search-module-form/search-module-form.component';
import { SearchComponent } from './pages/search/search.component';
import { AddOrEditComponent } from './pages/add-or-edit/add-or-edit.component';
import { AddOrEditFormComponent } from './components/add-or-edit-form/add-or-edit-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { SelectEmpresaComponent } from './pages/select-empresa/select-empresa.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { ManageCSVComponent } from './components/manage-csv/manage-csv.component';
import { SelectComponent } from './components/select/select.component';
import {TabComponent} from './components/tabs/tab.component';
import {TabsComponent} from './components/tabs/tabs.component';
import { PrestamosComponent } from './pages/prestamos/prestamos.component';
import { PrestamosAddOrEditComponent } from './pages/prestamos-add-or-edit/prestamos-add-or-edit.component';
import { PagosComponent } from './pages/pagos/pagos.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AboutComponent } from './pages/about/about.component';
import { TermsComponent } from './pages/terms/terms.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		DashboardComponent,
		PrincipalMenuComponent,
		HeaderComponent,
		FooterComponent,
		UsuariosComponent,
		UsuariosAddOrEditComponent,
		SearchModuleFormComponent,
		SearchComponent,
		AddOrEditComponent,
		AddOrEditFormComponent,
		EditProfileComponent,
		SelectEmpresaComponent,
		ManageCSVComponent,
		SelectComponent,
		TabsComponent,
    	TabComponent,
		PrestamosComponent,
		PrestamosAddOrEditComponent,
		PagosComponent,
		ClientsComponent,
		AboutComponent,
		TermsComponent
	],
	imports: [
		SweetAlert2Module.forRoot(),
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		DataTablesModule,
		ReactiveFormsModule,
		NgbModule,
    	NgMultiSelectDropDownModule.forRoot(),
		NgxDaterangepickerMd.forRoot(),
		GoogleMapsModule,
	],
	providers: [Nav, RequestService],
	bootstrap: [AppComponent]
})
export class AppModule { }
