<div id="kt_header_mobile" class="header-mobile align-items-center  header-mobile-fixed " >
  <!--begin::Logo-->
  <a href="javascript:void(0);">
    <h1>Proxima</h1>
    <!-- <img alt="Logo" style="width: 150px;" src="assets/media/logos/logo-tradekit.png"/> -->
  </a>
  <!--end::Logo-->
  <!--begin::Toolbar-->
  <div class="d-flex align-items-center">
    <!--begin::Aside Mobile Toggle-->
    <!--<button class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
      <span></span>
    </button>-->
    <!--end::Aside Mobile Toggle-->

    <!--begin::Header Menu Mobile Toggle-->
    <button class="btn p-0 burger-icon ml-4" id="__kt_header_mobile_toggle" (click)="toggleMenu()">
      <span></span>
    </button>
    <!--end::Header Menu Mobile Toggle-->

    <!--begin::Topbar Mobile Toggle-->
    <button class="btn btn-hover-text-primary p-0 ml-2" id="__kt_header_mobile_topbar_toggle" (click)="toggleUser()">
      <span class="svg-icon svg-icon-xl"><!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24"/>
                <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fill-rule="nonzero"/>
            </g>
        </svg><!--end::Svg Icon-->
      </span>
    </button>
    <!--end::Topbar Mobile Toggle-->

  </div>
  <!--end::Toolbar-->
</div>
  

<!--end::Header Mobile-->
  <!--begin::Page-->
    <!--begin::Aside-->
    <div class="aside aside-left  aside-fixed  d-flex flex-column flex-row-auto"  id="kt_aside">
      <!--begin::Brand-->
      <div class="brand flex-column-auto principal-menu" id="kt_brand" >
        <!--begin::Logo-->
        <a href="javascript:void(0);" class="brand-logo" style="margin:auto;">
          <img alt="Logo" style="width: 65px;" src="assets/media/logos/logo-tradekit.png"/>
          <h1>Proxima</h1>
          <!-- <img alt="Logo" style="width: 65px;" src="assets/media/logos/logo-tradekit.png"/> -->
        </a>
        <!--end::Logo-->
      </div>
      <div class="aside-menu-wrapper flex-column-fluid catojisa-menu-background" id="kt_aside_menu_wrapper">

        <!--begin::Menu Container-->
        <div
          id="kt_aside_menu"
          class="aside-menu catojisa-menu-background"
          data-menu-vertical="1"
          data-menu-scroll="1" data-menu-dropdown-timeout="500">
          <!--begin::Menu Nav-->
          <ul class="menu-nav catojisa-menu-background" >
            <li class="menu-item  " aria-haspopup="true" [ngClass]="{'menu-item-active': inDashboard }">
              <a routerLink="/dashboard" class="menu-link ">
                <span class="svg-icon menu-icon"><!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon points="0 0 24 0 24 24 0 24"/>
                        <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                              fill="#000000" fill-rule="nonzero"/>
                        <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                              fill="#000000" opacity="0.3"/>
                    </g>
                  </svg>
                </span>
                <span class="menu-text">Dashboard</span></a>
            </li>
            <li class="menu-section ">
              <h4 class="menu-text">Módulos</h4>
              <i class="menu-icon ki ki-bold-more-hor icon-md"></i>
            </li>
            <ng-container *ngFor="let menu of req.menues">
              <li class="menu-item  menu-item-parent" aria-haspopup="true" *ngIf="menu.title != 'Dashboard' && menu.browse"
                  [ngClass]="{'menu-item-active': menu.active }"  (click)='openMenu(menu)'>
                <span class="menu-link" (click)='go("/search",{module: menu.url})'>
                  <span class="svg-icon menu-icon"><!--begin::Svg Icon | path:assets/media/svg/icons/Design/Layers.svg-->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <polygon points="0 0 24 0 24 24 0 24"/>
                          <path d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
                                fill="#000000" fill-rule="nonzero"/>
                          <path d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
                                fill="#000000" opacity="0.3"/>
                      </g>
                    </svg>
                  </span>
                  <span class="menu-text">{{menu.title}}</span>
                  <i class="menu-arrow" *ngIf="menu.childrens"></i>
                </span>
                <ng-container *ngIf="menu.childrens">
                  <div class="menu-submenu "  style="display:block;" *ngIf="menu.opened">
                    <ul class="menu-subnav" >
                      <ng-container *ngFor="let child of menu.childrens">
                        <li class="menu-item " aria-haspopup="true"
                            [ngClass]="{'menu-item-active': child.active }" *ngIf="child.browse">
                          <a href="javascript:void(0);" class="menu-link" (click)='go("/search",{module: child.url})'>
                            <i class="menu-bullet menu-bullet-dot"><span></span></i>
                            <span class="menu-text">{{child.title === "Users" ? 'Usuarios' : child.title}}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </ng-container>
              </li>
            </ng-container>

<!--            <li class="menu-item  menu-item-parent" aria-haspopup="true">-->
<!--                <span class="menu-link" (click)='gotoSelectEmpresa()'-->
<!--                style="    background: #d70002;color:white;">-->
<!--                  <i style="color:white;margin-top:3px" class="ki ki-arrow-back"></i>-->
<!--                  <span class="menu-text" style="color:white;display: block;margin-left: 22px;margin-top:3px;">Seleccionar empresa</span>-->
<!--                </span>-->

<!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
