<div id="kt_quick_user" class="offcanvas offcanvas-right p-10">
  <!--begin::Header-->
  <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
    <h3 class="font-weight-bold m-0">
      Mi perfil
    </h3>
    <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
      <i class="ki ki-close icon-xs text-muted"></i>
    </a>
  </div>
  <!--end::Header-->

  <!--begin::Content-->
  <div class="offcanvas-content pr-5 mr-n5">
    <!--begin::Header-->
    <div class="d-flex align-items-center mt-5">
      <div class="symbol symbol-100 mr-5">
        <div class="symbol-label" style="background-image:url({{req.loggedUser.avatar}})"></div>
        <i class="symbol-badge bg-success"></i>
      </div>
      <div class="d-flex flex-column">
        <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
          {{req.loggedUser.nombres}} {{req.loggedUser.apellidos}}
        </a>
        <!--<div class="text-muted mt-1">
          Director contabilidad
        </div>-->
        <div class="navi mt-2">
          <a href="#" class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"/>
                            <path d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                  fill="#000000"/>
                            <circle fill="#000000" opacity="0.3" cx="19.5" cy="17.5" r="2.5"/>
                        </g>
                    </svg><!--end::Svg Icon-->
                  </span>
                </span>
                <span class="navi-text text-muted text-hover-primary">{{req.loggedUser.email}}</span>
              </span>
          </a>
          <a href="javascript:void(0)" class="btn btn-sm btn-light-danger font-weight-bolder py-2 px-5" (click)="logout()">Cerrar sesión</a>
        </div>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Separator-->
    <div class="separator separator-dashed mt-8 mb-5"></div>
    <!--end::Separator-->

    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 p-0">
      <!--begin::Item-->
      <a href="javascript:void(0);" class="navi-item">
        <div class="navi-link">
          <div class="symbol symbol-40 bg-light mr-3">
            <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M13.2070325,4 C13.0721672,4.47683179 13,4.97998812 13,5.5 C13,8.53756612 15.4624339,11 18.5,11 C19.0200119,11 19.5231682,10.9278328 20,10.7929675 L20,17 C20,18.6568542 18.6568542,20 17,20 L7,20 C5.34314575,20 4,18.6568542 4,17 L4,7 C4,5.34314575 5.34314575,4 7,4 L13.2070325,4 Z"
                                fill="#000000"/>
                          <circle fill="#000000" opacity="0.3" cx="18.5" cy="5.5" r="2.5"/>
                      </g>
                  </svg>
                </span>
            </div>
          </div>
          <div class="navi-text">
            <div class="font-weight-bold" routerLink="/profile">
              Editar mi perfil
            </div>
          </div>
        </div>
      </a>
      <!--end:Item-->

      <!--begin::Item-->
      <a href="javascript:void(0);" class="navi-item">
        <div class="navi-link">
          <div class="symbol symbol-40 bg-light mr-3">
            <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M6,2 L18,2 C18.5522847,2 19,2.44771525 19,3 L19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 L5,3 C5,2.44771525 5.44771525,2 6,2 Z M7.5,5 C7.22385763,5 7,5.22385763 7,5.5 C7,5.77614237 7.22385763,6 7.5,6 L13.5,6 C13.7761424,6 14,5.77614237 14,5.5 C14,5.22385763 13.7761424,5 13.5,5 L7.5,5 Z M7.5,7 C7.22385763,7 7,7.22385763 7,7.5 C7,7.77614237 7.22385763,8 7.5,8 L10.5,8 C10.7761424,8 11,7.77614237 11,7.5 C11,7.22385763 10.7761424,7 10.5,7 L7.5,7 Z"
                                fill="#000000" opacity="0.3"/>
                          <path d="M3.79274528,6.57253826 L12,12.5 L20.2072547,6.57253826 C20.4311176,6.4108595 20.7436609,6.46126971 20.9053396,6.68513259 C20.9668779,6.77033951 21,6.87277228 21,6.97787787 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,6.97787787 C3,6.70173549 3.22385763,6.47787787 3.5,6.47787787 C3.60510559,6.47787787 3.70753836,6.51099993 3.79274528,6.57253826 Z"
                                fill="#000000"/>
                      </g>
                  </svg><!--end::Svg Icon-->
                </span>
            </div>
          </div>
          <div class="navi-text" (click)="goTareas()">
            <div class="font-weight-bold" >
              Mis tareas
            </div>
            <div class="text-muted">
              Todas las tareas asignadas
            </div>
          </div>
        </div>
      </a>
      <!--end:Item-->
    </div>
    <!--end::Nav-->
    <!--begin::Separator-->
    <div class="separator separator-dashed my-7"></div>
    <!--end::Separator-->
    <!--begin::Notifications-->
  </div>
</div>

<!--end::Notifications-->
<div class="footer bg-white py-4 d-flex flex-lg-column wrapper" id="kt_footer" style="display: block;">
  <!--begin::Container-->
  <div class=" container-fluid  d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2">2023&copy;</span>
      <a href="javascript:void(0);" target="_blank"
         class="text-dark-75 text-hover-primary">{{APP_NAME}}</a>
    </div>
    <!--end::Copyright-->
  </div>
  <!--end::Container-->
</div>

